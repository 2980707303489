<template>
  <v-container fluid class="pa-0">
    <v-toolbar>
      <v-slide-group>
        <v-slide-item class="mt-4 mr-2">
          <v-btn v-if="isResponsableOrAdmin || hasPermission('add_profile')" @click="switchDrawer()" color="primary">Nouvel intervenant</v-btn>
        </v-slide-item>
        <v-slide-item class="mt-4">
          <v-text-field label="Nom" v-model="search_name"></v-text-field>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>
    <v-row class="ma-0">
      <v-col order-md="1" order="2" cols="12" md="5" id="intervenant-list">
        <v-tabs>
          <v-tab @click="lastTabClicked('prestataire')">
            Prestataires
          </v-tab>
          <v-tabs-slider :color="tabColor"></v-tabs-slider>
          <v-tab-item>
            <PrestataireList/>
          </v-tab-item>
        </v-tabs>

      </v-col>
      <v-col order-md="2" order="1" md="7" cols="12" id="intervenant-view">
        <router-view @edit-intervenant="editIntervenant()"></router-view>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import PrestataireList from "@/components/intervenants/PrestataireList";
import NewIntervenantSidebar from "@/components/intervenants/NewIntervenantSidebar";
import {AuthMixin} from "@/mixins/util/AuthMixin";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";

export default {
  name: "Intervenants",
  components: {PrestataireList},
  mixins: [AuthMixin, DrawerMixin],
  async mounted() {
    let name = this.$route.query.name;
    if (this.$store.getters["profile/profiles"].length === 0) {
      await this.$store.dispatch("profile/fetchProfiles");
    }
    if (this.$store.getters["groups/groups"].length === 0) {
      await this.$store.dispatch("groups/fetchGroups");
    }
    if(name !== undefined) {
      this.search_name = name;
    }
  },
  computed: {
    groups_intervenant() {
      return this.$store.getters["groups/groups_intervenant"];
    },
    selected_intervenant() {
      return this.$store.getters["profile/getSelectedProfile"];
    },
    search_name: {
      get() {
        return this.$store.getters["profile/getSearchName"];
      },
      set(value) {
        return this.$store.commit("profile/setSearchName", value)
      }
    },
  },
  data() {
    return {
      dialog: false,
      tabColor: "primary",
      operation: "create",
      intervenantToUpdate: {},
      intervenant_id: 0,
    }
  },
  methods: {
    editIntervenant() {
      let properties = {
        groups: this.groups_intervenant,
        operation: "update",
        key: this.intervenant_id,
        intervenantToUpdate: this.selected_intervenant,
      }
      this.openDrawerDialog(NewIntervenantSidebar, properties);
    },
    closeDrawer() {
      this.closeDrawerDialog();
    },
    switchDrawer() {
      let properties = {
        groups: this.groups_intervenant,
        operation: this.operation,
        key: this.intervenant_id,
        intervenantToUpdate: this.intervenantToUpdate,
      }
      this.openDrawerDialog(NewIntervenantSidebar, properties);
    },
    lastTabClicked(tab) {
      this.tabColor = tab === "prestataire" ? "primary" : "secondary";
    }
  }
}
</script>

<style scoped>

</style>